import { Button, Container, Form, InputGroup, Table } from 'react-bootstrap';
import { getDataFromAPI } from '../../utils/query';
import { useState } from 'react';

const CheckStoreRankPage = () => {
    const [query, setQuery] = useState();
    const [ranks, setRanks] = useState([]);

    const fetchStoreRank = async (e) => {
        e.preventDefault();
        if (query) {
            setRanks([]);
            const res = await getDataFromAPI('rankchecker/get-store-rank-by-name/?query=' + query);
            setRanks(res.data);
        }
    }

    return (
        <Container className="check-store-rank-page">
            <div className="header">
                <Form onSubmit={fetchStoreRank}>
                    <InputGroup className="mb-3">
                        <Form.Control value={query} onChange={e => setQuery(e.target.value)} placeholder="search store by name" />
                        <Button type="submit">Search</Button>
                    </InputGroup>
                </Form>
            </div>
            <Table className="ranks" striped hover bordered>
                <thead>
                    <tr>
                        <th>Store</th>
                        <th>Rank</th>
                    </tr>
                </thead>
                <tbody>

                    {ranks.map((rank, index) => (
                        <tr key={index}>
                            <td>

                                {rank['store_name']}
                            </td>
                            <td>{rank['rank']}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    )
}

export default CheckStoreRankPage;