import Container from 'react-bootstrap/Container';
import './styles.scss';
import FilterByStore from '../../components/filters/store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { Form, Modal, Tab, Table, Tabs } from 'react-bootstrap';
import OverviewReviews from '../../components/product-reviews/overview-reviews';
import DetailReviews from '../../components/product-reviews/detail-reviews';

const ProductReviewsPage = () => {
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);

    const [products, setProducts] = useState([]);
    const [reviews, setReviews] = useState({});
    const [productInModal, setProductInModal] = useState(null);
    const [showOnlyProductsHaveReviews, setShowOnlyProductsHaveReviews] = useState(false);

    useEffect(() => {
        fetchProductsByStore();
    }, [selectedStoreID]);

    const fetchProductsByStore = async () => {
        const res = await getDataFromAPI('resources/products/?store=' + selectedStoreID);
        setProducts(res.data);
        const productIDs = [];
        for (let i = 0; i < res.data.length; i++) {
            productIDs.push(res.data[i].id);
        }

        const resReviews = await updateDataAPI('POST', 'rankchecker/get-reviews-by-product-ids/', {
            productIDs
        });
        const reviews = {};
        for (let i = 0; i < resReviews.data.length; i++) {
            const productID = resReviews.data[i]['product'];
            if (reviews[productID] === undefined) {
                reviews[productID] = [resReviews.data[i]];
            } else {
                reviews[productID].push(resReviews.data[i]);
            }
        }
        console.log(reviews);
        setReviews(reviews);
    }

    return (
        <Container className="product-reviews-container">
            <div className="filters">

                <FilterByStore />
                <Form >
                    <Form.Group controlId="show-only-products-have-review">
                        <Form.Check type="checkbox" label="Only Product Have Reviews" checked={showOnlyProductsHaveReviews}
                            onChange={e => setShowOnlyProductsHaveReviews(!showOnlyProductsHaveReviews)} />
                    </Form.Group>
                </Form>
            </div>

            <div className="nb-products">{products.length} products</div>
            <Table className="ranks" striped hover bordered>
                <thead>
                    <tr>
                        <th>Thumbnail</th>
                        <th>Title</th>
                        <th>Reviews</th>
                    </tr>
                </thead>
                <tbody>
                    {products.filter(product => {
                        if (showOnlyProductsHaveReviews && (reviews[product.id] === undefined || reviews[product.id].length === 0)) {
                            return false;
                        }
                        return true;
                    }).map(product => (
                        <tr key={product.id}>
                            <td>
                                {product.thumbnail && (
                                    <img src={product.thumbnail} />
                                )}
                            </td>
                            <td>
                                <a target="_blank" href={product.url}>

                                    {product.title}
                                </a>
                            </td>
                            <td className="nb-reviews-cell">
                                {reviews[product.id] !== undefined && reviews[product.id].length > 0 && (
                                    <div onClick={() => setProductInModal(product)}>
                                        <OverviewReviews saleStatus="FREE" reviews={reviews[product.id].filter(r => r.saleStatus === 'FREE')} />
                                        <OverviewReviews saleStatus="PAID" reviews={reviews[product.id].filter(r => r.saleStatus === 'PAID')} />
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {productInModal && (
                <Modal show={true} onHide={() => setProductInModal(null)} size="xl" className="show-full-description-modal">
                    <Modal.Header closeButton>
                        {productInModal.title}
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs
                            defaultActiveKey="PAID"
                            id="detail-reviews"
                            className="mb-3"
                        >
                            <Tab eventKey="FREE" title="FREE">
                                <DetailReviews reviews={reviews[productInModal.id].filter(r => r.saleStatus === 'FREE')} />
                            </Tab>
                            <Tab eventKey="PAID" title="PAID">
                                <DetailReviews reviews={reviews[productInModal.id].filter(r => r.saleStatus === 'PAID')} />
                            </Tab>
                        </Tabs>
                    </Modal.Body>
                </Modal>
            )}
        </Container>
    )
}

export default ProductReviewsPage;