import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './styles.scss';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Constant from '../../Constant';

const Header = () => {
    const [userRole, setUserRole] = useState(null);
    const location = useLocation();

    useEffect(() => {
        setUserRole(localStorage.getItem(Constant.LOCAL_STORAGE_ROLE));
    }, []);

    const logout = () => {
        localStorage.removeItem(Constant.LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(Constant.LOCAL_STORAGE_ROLE);
        localStorage.removeItem(Constant.LOCAL_STORAGE_USER_EMAIL);
        window.location.href = '/login';
    }

    return (
        <Navbar bg="light" className="header">
            <Container fluid>
                <Navbar.Brand href="/">
                    <img className="logo" src="/logo.png" alt="logo" />
                </Navbar.Brand>
                <Nav className="me-auto">
                    <Nav.Link href="/keyword-research" className={location.pathname === '/keyword-research' && 'active'}>
                        Keyword Research
                    </Nav.Link>
                </Nav>
                {Constant.ROUTE_PERMISSIONS['/check-store-rank'].indexOf(userRole) > -1 && (
                    <Nav className="me-auto">
                        <Nav.Link href="/check-store-rank" className={location.pathname === '/check-store-rank' && 'active'}>
                            Check Store Rank
                        </Nav.Link>
                    </Nav>
                )}
                {Constant.ROUTE_PERMISSIONS['/tpt-search-result'].indexOf(userRole) > -1 && (
                    <Nav className="me-auto">
                        <Nav.Link href="/tpt-search-result" className={location.pathname === '/tpt-search-result' && 'active'}>
                            TPT Search Result
                        </Nav.Link>
                    </Nav>
                )}
                {Constant.ROUTE_PERMISSIONS['/store-rank-history'].indexOf(userRole) > -1 && (
                    <Nav className="me-auto">
                        <Nav.Link href="/store-rank-history" className={location.pathname === '/store-rank-history' && 'active'}>
                            Store Rank History
                        </Nav.Link>
                    </Nav>
                )}
                {Constant.ROUTE_PERMISSIONS['/product-reviews'].indexOf(userRole) > -1 && (
                    <Nav className="me-auto">
                        <Nav.Link href="/product-reviews" className={location.pathname === '/product-reviews' && 'active'}>
                            Product Reviews
                        </Nav.Link>
                    </Nav>
                )}
                {userRole && (
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text className="logout-btn" onClick={logout}>
                            Logout
                        </Navbar.Text>
                    </Navbar.Collapse>
                )}
            </Container>
        </Navbar>
    );
}

export default Header;