import { useEffect, useState } from 'react';
import { Button, Container, Form, InputGroup, Modal, Spinner } from 'react-bootstrap';
import { getDataFromAPI } from '../../utils/query';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const TPTSearchResultPage = () => {
    const [query, setQuery] = useState();
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [popularity, setPopularity] = useState(null);
    const [difficulty, setDifficulty] = useState(null);
    const [zoomingImage, setZoomingImage] = useState();
    const [showingFullDescription, setShowingFullDescription] = useState();
    const [ranks, setRanks] = useState({});

    useEffect(() => {
        const keyword = (new URLSearchParams(window.location.search)).get('keyword');
        if (keyword) {
            setQuery(keyword);
            fetchSearchResult();
        }
    }, []);

    const fetchSearchResult = async (e) => {
        if (e) {
            e.preventDefault();
        }
        if (query) {
            setLoading(true);
            const resultRes = await getDataFromAPI('rankchecker/get-search-result/?query=' + query);
            const storeIDs = resultRes.data['resources'].map(r => r['author']['id']);
            getDataFromAPI('rankchecker/get-store-ranks-by-ids/?ids=' + storeIDs.join(',')).then(ranksRes => setRanks(ranksRes.data));
            setResult(resultRes.data['resources']);
            setPopularity(resultRes.data['popularity']);
            setDifficulty(resultRes.data['difficulty']);

            setLoading(false);
        }
    }

    return (
        <Container className="tpt-search-result-page" fluid>
            <div className="header">
                <Form onSubmit={fetchSearchResult}>
                    <InputGroup className="mb-3">
                        <Form.Control value={query} onChange={e => setQuery(e.target.value)} placeholder="search keyword" />
                        <Button type="submit">Search</Button>
                    </InputGroup>
                </Form>
                {result && (
                    <div>
                        <div>Popularity: {popularity ? popularity : 'not found'}</div>
                        <div>Difficulty: {difficulty ? difficulty : 'not found'}</div>
                    </div>
                )}
            </div>
            {result && (
                <div className="result">
                    {result.map((item, itemIndex) => {
                        let preview;
                        if (item.assets.previews) {
                            preview = item.assets.previews.find(p => p.fileType === 'pdf');
                        }
                        return (
                            <div className="result-item" key={item.id}>
                                <span className="item-index">{itemIndex + 1}.</span>
                                <div className="item-thumbnails">
                                    <img src={item.assets.thumbnails[0].originalUrl} onClick={() => setZoomingImage(item.assets.thumbnails[0].originalUrl)} />
                                    <div className="small-thumbs">
                                        {item.assets.thumbnails.slice(1).map((thumb, thumbIndex) => (
                                            <img src={thumb.originalUrl} key={thumbIndex} onClick={() => setZoomingImage(thumb.originalUrl)} />
                                        ))}
                                    </div>
                                    {preview && (
                                        <a href={preview.signedUrl} target="_blank">Preview</a>
                                    )}
                                </div>
                                <div className="item-info">
                                    <div className="item-title">
                                        <a href={'https://teacherspayteachers.com/Product/' + item.slug} target="_blank">{item.title}</a>
                                    </div>
                                    <div className="item-store">
                                        <img src={item.author.icon.url} />
                                        <a href={'https://teacherspayteachers.com/Store/' + item.author.slug} target="_blank">{item.author.name}</a>
                                        {' '} (rank: {ranks[item.author.id] !== undefined ? ranks[item.author.id] : 'loading...'})
                                    </div>
                                    <div className="item-desc">
                                        {item.descriptionSnippet}
                                        <span className="see-more" onClick={() => setShowingFullDescription(item.description)}>See more</span>
                                    </div>
                                    <div className="item-meta">
                                        Subjects: {item.subjectAreas.map(subject => subject.name).join(', ')}
                                    </div>
                                    <div className="item-meta">
                                        Grades: {item.gradeLevels.map(grade => grade.name).join(', ')}
                                    </div>
                                    <div className="item-meta">
                                        Types: {item.resourceCategories.map(cat => cat.name).join(', ')}
                                    </div>
                                    <div className="item-meta">
                                        Standards: {item.standards.map(standard => standard.name).join(', ')}
                                    </div>
                                </div>
                                <div className="item-price-rate">
                                    <div className="item-price">
                                        {item.pricing.nonTransferableLicenses.discountPrice > 0 ? (
                                            <span>${item.pricing.nonTransferableLicenses.discountPrice} (<s>${item.pricing.nonTransferableLicenses.price}</s>)</span>
                                        ) : (
                                            <span>${item.pricing.nonTransferableLicenses.price}</span>
                                        )}
                                    </div>
                                    <div className="item-rate">
                                        <FontAwesomeIcon icon={faStar} />
                                        {item.overallQualityScore} ({item.totalEvaluations} rates)
                                    </div>
                                    <div className="item-bundle">
                                        {item.__typename.indexOf('Bundle') > -1 && (
                                            <strong>Bundle</strong>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
            {zoomingImage && (
                <Modal show={true} onHide={() => setZoomingImage(null)} size="lg" className="zooming-image-modal">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={zoomingImage} />
                    </Modal.Body>
                </Modal>
            )}
            {showingFullDescription && (
                <Modal show={true} onHide={() => setShowingFullDescription(null)} size="lg" className="show-full-description-modal">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{ __html: showingFullDescription }}></div>
                    </Modal.Body>
                </Modal>
            )}
            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default TPTSearchResultPage;