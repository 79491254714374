import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setStores, setSelectedStoreID } from '../filtersSlice';
import { useHistory, useLocation } from 'react-router-dom';
import { getDataFromAPI } from '../../../utils/query';

const FilterByStore = ({ allStores = true, hasAllOption = false, showMetaLink = true, showRateLimit = false }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const stores = useSelector((state) => state.filters.stores);
    const selectedStoreID = useSelector((state) => state.filters.selectedStoreID);

    useEffect(() => {
        fetchStores();
    }, []);

    useEffect(() => {
        if (selectedStoreID !== null && !isNaN(selectedStoreID)) {
            const params = new URLSearchParams({ 'selectedStoreID': selectedStoreID });
            history.replace({ pathname: location.pathname, search: params.toString() });
        }
    }, [selectedStoreID]);

    const fetchStores = async () => {
        const res = await getDataFromAPI('resources/stores/' + (allStores ? '' : '?active=true'));
        dispatch(setStores(res.data));
        const selectedStoreID = (new URLSearchParams(window.location.search)).get('selectedStoreID');
        if (selectedStoreID !== null && !isNaN(selectedStoreID)) {
            dispatch(setSelectedStoreID(parseInt(selectedStoreID)));
        } else {
            dispatch(setSelectedStoreID(res.data[0].id));
        }
    }

    return (
        <div>
            <Form.Select onChange={e => {
                dispatch(setSelectedStoreID(e.target.value !== 'all' ? parseInt(e.target.value) : null));
            }} value={selectedStoreID ? selectedStoreID : undefined}>
                {hasAllOption && (
                    <option value="all">All</option>
                )}
                {stores.map(store => (
                    <option value={store.id} key={store.id}>
                        {store.id} {store.name}
                    </option>
                ))}
            </Form.Select>
            {showMetaLink && (
                <div className="meta-links">
                    {stores.find(s => s.id === selectedStoreID)?.worksheetThumbnailFormUrl && (
                        <div>
                            <a href={stores.find(s => s.id === selectedStoreID).worksheetThumbnailFormUrl} target="_blank" rel="noreferrer">Worksheet Thumbnail Form</a>
                        </div>
                    )}
                    {stores.find(s => s.id === selectedStoreID)?.taskcardThumbnailFormUrl && (
                        <div>
                            <a href={stores.find(s => s.id === selectedStoreID).taskcardThumbnailFormUrl} target="_blank" rel="noreferrer">Taskcard Thumbnail Form</a>
                        </div>
                    )}
                </div>
            )}
            {showRateLimit && stores.find(s => s.id === selectedStoreID)?.rateLimit && (
                <div>Rate Limit: {stores.find(s => s.id === selectedStoreID)?.rateLimit}</div>
            )}
        </div>
    );
}

export default FilterByStore;