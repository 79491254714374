import { ListGroup } from 'react-bootstrap';

const DetailReviews = ({ reviews }) => {
    return (
        <div>
            {reviews.map((review, index) => (
                <ListGroup key={index} className={'review-in-modal overall-' + review['overallQualHowSatisfiedStars']}>
                    {review['whichGradeUsedWith'] && (
                        <ListGroup.Item className="d-flex justify-content-between">
                            <label>whichGradeUsedWith</label>
                            <div>{review['whichGradeUsedWith']}</div>
                        </ListGroup.Item>
                    )}
                    {review['thisResourceWasEngagingMyStudents'] && (
                        <ListGroup.Item className="d-flex justify-content-between">
                            <label>thisResourceWasEngagingMyStudents</label>
                            <div>{review['thisResourceWasEngagingMyStudents']}</div>
                        </ListGroup.Item>
                    )}
                    {review['overallQualHowSatisfiedStars'] && (
                        <ListGroup.Item className="d-flex justify-content-between">
                            <label>overallQualHowSatisfiedStars</label>
                            <div>{review['overallQualHowSatisfiedStars']}</div>
                        </ListGroup.Item>
                    )}
                    {review['howWasResourceUsed'] && (
                        <ListGroup.Item className="d-flex justify-content-between">
                            <label>howWasResourceUsed</label>
                            <div>{review['howWasResourceUsed']}</div>
                        </ListGroup.Item>
                    )}
                    {review['resourceWorkedForDistanceLearning'] && (
                        <ListGroup.Item className="d-flex justify-content-between">
                            <label>resourceWorkedForDistanceLearning</label>
                            <div>{review['resourceWorkedForDistanceLearning']}</div>
                        </ListGroup.Item>
                    )}
                    {review['specialEdStudents'] && (
                        <ListGroup.Item className="d-flex justify-content-between">
                            <label>specialEdStudents</label>
                            <div>{review['specialEdStudents']}</div>
                        </ListGroup.Item>
                    )}
                    {review['buyerExperience'] && (
                        <ListGroup.Item className="d-flex justify-content-between">
                            <label>buyerExperience</label>
                            <div>{review['buyerExperience']}</div>
                        </ListGroup.Item>
                    )}
                    {review['thoroughness'] && (
                        <ListGroup.Item className="d-flex justify-content-between">
                            <label>thoroughness</label>
                            <div>{review['thoroughness']}</div>
                        </ListGroup.Item>
                    )}
                    {review['practicality'] && (
                        <ListGroup.Item className="d-flex justify-content-between">
                            <label>practicality</label>
                            <div>{review['practicality']}</div>
                        </ListGroup.Item>
                    )}
                    {review['overallQuality'] && (
                        <ListGroup.Item className="d-flex justify-content-between">
                            <label>overallQuality</label>
                            <div>{review['overallQuality']}</div>
                        </ListGroup.Item>
                    )}
                    {review['creativity'] && (
                        <ListGroup.Item className="d-flex justify-content-between">
                            <label>creativity</label>
                            <div>{review['creativity']}</div>
                        </ListGroup.Item>
                    )}
                    {review['clarity'] && (
                        <ListGroup.Item className="d-flex justify-content-between">
                            <label>clarity</label>
                            <div>{review['clarity']}</div>
                        </ListGroup.Item>
                    )}
                    {review['accuracy'] && (
                        <ListGroup.Item className="d-flex justify-content-between">
                            <label>accuracy</label>
                            <div>{review['accuracy']}</div>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            ))}
        </div>
    )
}

export default DetailReviews;