import { useEffect, useState } from 'react';
import { Badge, Button, Container, Form, InputGroup, ListGroup, Pagination, Spinner, Table } from 'react-bootstrap';
import { getDataFromAPI, updateDataAPI } from '../../utils/query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare, faSearch, faClose } from '@fortawesome/free-solid-svg-icons';
import Highlighter from 'react-highlight-words';
import FilterByDateRange from '../../components/filters/datepicker';
import moment from 'moment';

import './styles.scss';
import { useSelector } from 'react-redux';

const KeywordResearchPage = ({ }) => {
    const startDate = useSelector((state) => state.filters.startDate);
    const endDate = useSelector((state) => state.filters.endDate);

    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState('');
    const [submitedQuery, setSubmitedQuery] = useState('');
    const [keywordSuggestions, setKeywordSuggestions] = useState([]);
    const [result, setResult] = useState([]);
    const [sortingColumn, setSortingColumn] = useState('popularity');
    const [sortingDirection, setSortingDirection] = useState('down');
    const [selectedDifficulty, setSelectedDifficulty] = useState('all');
    const [newKeyword, setNewKeyword] = useState();
    const [productRankings, setProductRankings] = useState({});
    const [productSales, setProductSales] = useState({});
    const [nbPages, setNbPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchKeywordsByQuery();
    }, []);

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            fetchKeywordsByQuery();
        }
    }, [currentPage, submitedQuery, startDate, endDate]);

    // useEffect(() => {
    //     if (query) {
    //         fetchKeywordsByQuery();
    //     }
    // }, [sortingColumn, sortingDirection]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (query) {
                getKeywordSuggestions();
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [query]);

    const getKeywordSuggestions = async () => {
        const res = await getDataFromAPI('keywords/suggest-keywords/?query=' + query);
        setKeywordSuggestions(res.data);
    }

    const fetchKeywordsByQuery = async () => {
        console.log('fetch kewords');
        setLoading(true);
        let res, keywords;
        if (query) {
            let url = 'keywords/search-keywords/?query=' + query + '&startDate=' + startDate + '&endDate=' + endDate + '&page=' + (currentPage - 1);
            if (selectedDifficulty !== 'all') {
                url += '&difficulty=' + selectedDifficulty;
            }
            url += '&orderBy=' + sortingColumn;
            url += '&orderDir=' + sortingDirection;
            res = await getDataFromAPI(url);
            setNbPages(Math.ceil(res.data['count'] / 100));
            keywords = res.data['results'];
        } else {
            const startDate = moment().startOf('month').format('YYYY-MM-DD');
            const endDate = moment().endOf('month').format('YYYY-MM-DD');
            res = await getDataFromAPI('keywords/get-most-populated-keywords/?startDate=' + startDate + '&endDate=' + endDate);
            keywords = res.data;
        }

        const relatedProductIDs = [];
        const relatedProductTPTIDs = [];
        for (let i = 0; i < keywords.length; i++) {
            const relatedProducts = keywords[i].relatedProducts;
            if (relatedProducts) {
                for (let j = 0; j < relatedProducts.length; j++) {
                    if (relatedProductIDs.indexOf(relatedProducts[j].id) === -1) {
                        relatedProductIDs.push(relatedProducts[j].id);
                    }
                    if (relatedProductTPTIDs.indexOf(relatedProducts[j].tptID) === -1) {
                        relatedProductTPTIDs.push(relatedProducts[j].tptID);
                    }
                }
            }
        }

        await getProductKeywordRanks(relatedProductIDs);
        await getProductSales(relatedProductTPTIDs);

        setResult(keywords);
        setLoading(false);
    }

    const getProductKeywordRanks = async (relatedProductIDs) => {
        const res = await updateDataAPI('POST', 'rankchecker/get-product-keyword-ranks/', {
            ids: relatedProductIDs
        });
        setProductRankings(res.data);
    }

    const getProductSales = async (relatedProductTPTIDs) => {
        if (startDate !== null && endDate !== null) {
            const res = await updateDataAPI('POST', 'rankchecker/get-sales-by-tpt-ids/?startDate=' + startDate + '&endDate=' + endDate, {
                tptIDs: relatedProductTPTIDs
            });
            setProductSales(res.data);
        }
    }

    const addNewKeyword = async (e) => {
        e.preventDefault();
        if (newKeyword.length > 0) {
            try {
                await updateDataAPI('POST', 'keywords/keywords/', {
                    name: newKeyword
                });
                alert('thêm keyword thành công');
                setNewKeyword('');
            } catch (e) {
                alert('keyword đã tồn tại');
            }
        }
    }

    const formatProductRank = rank => {
        if (rank < 24) {
            return (
                <Badge bg="success" className="badge-rank">
                    <div>Page 1</div>
                    <div>Pos {rank}</div>
                </Badge>
            );
        }
        return (
            <Badge bg="info" className="badge-rank">
                <div>Page 2</div>
                <div>Pos {rank - 24}</div>
            </Badge>
        )
    }

    return (
        <Container className="keyword-research-container" fluid>
            <div className="header">
                <div className="filters-left">
                    <Form onSubmit={e => {
                        e.preventDefault();
                        setSubmitedQuery(query.toLowerCase());
                    }}>
                        <InputGroup className="mb-3 keyword-search-input">
                            <Form.Control value={query} onChange={e => setQuery(e.target.value)} placeholder="search keyword" />
                            <FontAwesomeIcon icon={faClose} className="delete-icon" onClick={() => setQuery('')} />
                            <Button type="submit">Search</Button>
                            {keywordSuggestions.length > 0 && query !== submitedQuery && (
                                <div className="keyword-suggestions">
                                    {keywordSuggestions.map((keyword, index) => (
                                        <div key={index} onClick={() => {
                                            setQuery(keyword);
                                            setSubmitedQuery(keyword);
                                            setKeywordSuggestions([]);
                                        }}>{keyword}</div>
                                    ))}
                                </div>
                            )}
                        </InputGroup>
                        <div>
                            <label>Duration</label>
                            <FilterByDateRange
                                defaultDateRange={{
                                    startDate: moment().startOf('month').toDate(),
                                    endDate: moment().endOf('month').toDate(),
                                }}
                            />
                        </div>
                        <div>
                            <label>Difficulty</label>
                            <select className="form-select" onChange={e => setSelectedDifficulty(e.target.value)}>
                                <option value="all">All</option>
                                <option value="easy">Easy</option>
                                <option value="medium">Medium</option>
                                <option value="hard">Hard</option>
                            </select>
                        </div>
                    </Form>
                </div>
                <div className="filters-right">
                    <Form onSubmit={addNewKeyword} className="add-new-keyword-form">
                        <InputGroup className="mb-3">
                            <Form.Control value={newKeyword} onChange={e => setNewKeyword(e.target.value.toLowerCase())} placeholder="new keyword" />
                            <Button type="submit" size="sm">Add new keyword</Button>
                        </InputGroup>
                    </Form>
                </div>
            </div>
            {(submitedQuery === null || submitedQuery === '') && (
                <h2>Top 1000 keywords by popularity</h2>
            )}
            {result.length > 0 ? (
                <Table className="result" striped hover bordered>
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                <span>Name</span>
                                {/* {sortingColumn === 'score' && sortingDirection === 'asc' && (
                                    <FontAwesomeIcon icon={faChevronCircleUp} />
                                )}
                                {sortingColumn === 'score' && sortingDirection === 'desc' && (
                                    <FontAwesomeIcon icon={faChevronCircleDown} />
                                )} */}
                            </th>
                            <th>
                                {/* onClick={() => updateSorting('popularity')} */}
                                <span>Popularity</span>
                                {/* {sortingColumn === 'popularity' && sortingDirection === 'asc' && (
                                    <FontAwesomeIcon icon={faChevronCircleUp} />
                                )}
                                {sortingColumn === 'popularity' && sortingDirection === 'desc' && (
                                    <FontAwesomeIcon icon={faChevronCircleDown} />
                                )} */}
                            </th>
                            <th>
                                <span>Difficulty</span>
                                {/* {sortingColumn === 'difficulty' && sortingDirection === 'asc' && (
                                    <FontAwesomeIcon icon={faChevronCircleUp} />
                                )}
                                {sortingColumn === 'difficulty' && sortingDirection === 'desc' && (
                                    <FontAwesomeIcon icon={faChevronCircleDown} />
                                )} */}
                            </th>
                            <th>
                                Related Products
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {result.map((keyword, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="keyword-cell">
                                    <Highlighter
                                        highlightClassName="YourHighlightClass"
                                        searchWords={submitedQuery.toLowerCase().trim().split(' ')}
                                        autoEscape={true}
                                        textToHighlight={keyword.name}
                                    />
                                    <a href={'https://www.teacherspayteachers.com/Browse/Search:' + keyword.name} target="_blank">
                                        <FontAwesomeIcon icon={faUpRightFromSquare} />
                                    </a>
                                    <a href={'/tpt-search-result?keyword=' + keyword.name} target="_blank">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </a>
                                </td>
                                <td>{keyword.popularity > -1 ? keyword.popularity : ''}</td>
                                <td>
                                    <span className={'diff-level diff-level-' + keyword.difficulty}>{keyword.difficulty}</span>
                                </td>
                                <td>
                                    {keyword.relatedProducts && (
                                        <ListGroup>
                                            {keyword.relatedProducts.map(product => (
                                                <ListGroup.Item key={product.id} className="d-flex justify-content-between">
                                                    <div className="product-preview">
                                                        {product.thumbnail && <img src={product.thumbnail} />}
                                                        <div>
                                                            <a href={product.url} target="_blank">
                                                                <Highlighter
                                                                    highlightClassName="YourHighlightClass"
                                                                    searchWords={[keyword.name]}
                                                                    autoEscape={true}
                                                                    textToHighlight={product.title}
                                                                />
                                                            </a>
                                                            <div>{product.price ? ('PAID: $' + product.price) : 'FREE'}</div>
                                                            <div>{product.nbRatings} rates</div>
                                                            <div>TPTID: {product.tptID}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {productRankings[product.id] && productRankings[product.id][keyword.id] && (
                                                            formatProductRank(productRankings[product.id][keyword.id])
                                                        )}
                                                        {productSales[product.tptID] > 0 && (
                                                            <Badge bg="danger">
                                                                {productSales[product.tptID]} sales
                                                            </Badge>
                                                        )}
                                                    </div>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <div>No result</div>
            )}
            {nbPages > 0 && query && (
                <div className="pagination-container">
                    <Pagination>
                        {currentPage > 1 && (
                            <>
                                <Pagination.First onClick={() => setCurrentPage(1)} />
                                <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />
                            </>
                        )}
                        {[3, 2, 1].map(index => (
                            currentPage > index && (
                                <Pagination.Item onClick={() => setCurrentPage(currentPage - index)} key={'page-before-active-' + index}>
                                    {currentPage - index}
                                </Pagination.Item>
                            )
                        ))}
                        <Pagination.Item active={true}>
                            {currentPage}
                        </Pagination.Item>
                        {[1, 2, 3].map(index => (
                            currentPage + index <= nbPages && (
                                <Pagination.Item onClick={() => setCurrentPage(currentPage + index)} key={'page-after-active-' + index}>
                                    {currentPage + index}
                                </Pagination.Item>
                            )
                        ))}
                        {currentPage < nbPages && (
                            <>
                                <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />
                                <Pagination.Last onClick={() => setCurrentPage(nbPages)} />
                            </>
                        )}
                    </Pagination>
                </div>
            )}
            {loading && (
                <div className="loading-container">
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </Container>
    )
}

export default KeywordResearchPage;