import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import LoginPage from './pages/login';
import HomePage from './pages/home';
import KeywordResearchPage from './pages/keyword-research';
import CheckStoreRankPage from './pages/check-store-rank';
import TPTSearchResultPage from './pages/tpt-search-result';
import StoreRankHistoryPage from './pages/store-rank-history';
import ProductReviewsPage from './pages/product-reviews';

function App() {
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route
                        exact
                        path="/login"
                        component={LoginPage}
                    />
                    <AuthRoute
                        exact
                        path="/"
                        component={HomePage}
                    />
                    <AuthRoute
                        exact
                        path="/keyword-research"
                        component={KeywordResearchPage}
                    />
                    <AuthRoute
                        exact
                        path="/check-store-rank"
                        component={CheckStoreRankPage}
                    />
                    <AuthRoute
                        exact
                        path="/tpt-search-result"
                        component={TPTSearchResultPage}
                    />
                    <AuthRoute
                        exact
                        path="/store-rank-history"
                        component={StoreRankHistoryPage}
                    />
                    <AuthRoute
                        exact
                        path="/product-reviews"
                        component={ProductReviewsPage}
                    />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
