import { createSlice } from '@reduxjs/toolkit';

export const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        stores: [],
        selectedStoreID: null,
        startDate: null,
        endDate: null,
    },
    reducers: {
        setStores: (state, action) => {
            state.stores = action.payload;
        },
        setSelectedStoreID: (state, action) => {
            state.selectedStoreID = action.payload;
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },
    },
})

export const {
    setStores,
    setSelectedStoreID,
    setStartDate,
    setEndDate,
} = filtersSlice.actions;

export default filtersSlice.reducer;