import { Badge } from 'react-bootstrap';

const OverviewReviews = ({ saleStatus, reviews }) => {
    if (reviews.length === 0) {
        return null;
    }

    return (
        <div className="overview-reviews">
            <div>{saleStatus}: {reviews.length} reviews</div>
            {reviews.filter(r => r['overallQualHowSatisfiedStars'] === 'extremely').length > 0 && (
                <div>
                    <Badge bg="success">{reviews.filter(r => r['overallQualHowSatisfiedStars'] === 'extremely').length}</Badge> 5-star reviews
                </div>
            )}
            {reviews.filter(r => r['overallQualHowSatisfiedStars'] === 'very').length > 0 && (
                <div>
                    <Badge bg="warning">{reviews.filter(r => r['overallQualHowSatisfiedStars'] === 'very').length}</Badge> 4-star reviews
                </div>
            )}
            {reviews.filter(r => r['overallQualHowSatisfiedStars'] === 'moderately').length > 0 && (
                <div>
                    <Badge bg="warning">{reviews.filter(r => r['overallQualHowSatisfiedStars'] === 'moderately').length}</Badge> 3-star reviews
                </div>
            )}
            {reviews.filter(r => r['overallQualHowSatisfiedStars'] === 'slightly').length > 0 && (
                <div>
                    <Badge bg="danger">{reviews.filter(r => r['overallQualHowSatisfiedStars'] === 'slightly').length}</Badge> 2-star reviews
                </div>
            )}
            {reviews.filter(r => r['overallQualHowSatisfiedStars'] === 'not_at_all').length > 0 && (
                <div>
                    <Badge bg="danger">{reviews.filter(r => r['overallQualHowSatisfiedStars'] === 'not_at_all').length}</Badge> 1-star reviews
                </div>
            )}
        </div>
    );
}

export default OverviewReviews;